import {authInterceptor, createHttpServiceConfig} from '@/services/httpFactories';
import { MONITORING_SERVICE_ENDPOINT, MONITORING_SESSION_SUGGESTIONS_RESOURCE_PATH, MONITORING_VARIABLES_RESOURCE_PATH } from './monitoringServiceConstants';

const monitoringServiceConfig = createHttpServiceConfig(MONITORING_SERVICE_ENDPOINT, MONITORING_VARIABLES_RESOURCE_PATH);

monitoringServiceConfig.addRequestInterceptor(authInterceptor);

export const logMonitoringService = (params) => monitoringServiceConfig.http({
    method: 'GET',
    params,
    timeout: 10000
});

export const fetchSessionSuggestionsFromLogs = (data) => monitoringServiceConfig.http({
    method: 'POST',
    url: MONITORING_SESSION_SUGGESTIONS_RESOURCE_PATH,
    data
});
